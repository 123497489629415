<template>
	<div>
    </div>
</template>

<script>
	export default {
		props: ['type'],

		data() {
			return {
				active: null
			}
		},

		created() {
			this.active = this.getVideo();
		},

		methods: {
			getVideo() {
				let data = [
					{
						name: 'football',
						videoUrl: '/video/main/football.mp4',
						title: 'Мировой футбол у вас в мобильном!',
						button: 'Смотреть',
						route: {
							name: 'live-sport',
							params: {sport: 'soccer'}
						}
					},
					{
						name: 'hockey',
						videoUrl: '/video/main/hockey.mp4',
						title: 'Хоккейные матчи онлайн!',
						button: 'Смотреть',
						route: {
							name: 'live-sport',
							params: {sport: 'hockey'}
						}
					},
					{
						name: 'tennis',
						videoUrl: '/video/main/tennis.mp4',
						title: 'Все турниры большого шлема онлайн!',
						button: 'Смотреть',
						route: {
							name: 'live-sport',
							params: {sport: 'tennis'}
						}
					},
					{
						name: 'goal',
						videoUrl: '/video/main/goal.mp4',
						title: 'Будь в игре!',
						button: 'Билеты на спорт',
						route: {
							name: 'tickets'
						}
					},
				];

				let random = this.getRandomInt(data.length);

				localStorage.setItem('videoNum', random);

				return data[random];
			},
			getRandomInt(max) {
				let random = Math.floor(Math.random() * Math.floor(max));

				if (random == localStorage.getItem('videoNum')) {
					return this.getRandomInt(max);
				}

				return random;
			}
		}
	}
</script>
