import Vue from "vue";

require("../../public/assets/js/index");

import settings_zet from "./settings_zet";
import settings_default from "./settings_default";
import all_settings from "./all_settings";

// Auth
window.auth = {};

// Error
window.error = {};

// Settings
window.settings = Object.assign({}, all_settings, settings_default);

if (location.hostname === 'localhost') {
	window.settings.fakeAuth();
}
