<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				error: null,
				loading: false,
				login: null,
			}
		},

		methods: {
			subscribe() {
				if (this.loading) return;

				this.loading = true;
				this.error = false;

				this.axios({url: '/a1/subscribe',  baseURL: '/'}).then(({data}) => {
					if (data.status === 'subscribed') {
						location.href = '/';
					} else {
						this.error = true;
						this.loading = false;
					}
				});
			},
		},
	}
</script>

<style scoped>

</style>
