<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				subscribed: null,
				error: null,
				smsError: null,
				loading: false,
				login: null,
			}
		},

		created() {
			this.axios.get('/api/profile').then(({data}) => {
				this.login = data;
			});
		},

		methods: {
			subscribe() {
				if (this.loading) return;

				this.loading = true;
				this.error = false;
				this.smsError = false;
				this.subscribed = null;

				this.axios.get('/api/subscribe?action=activate');

				setInterval(() => {
					if (this.subscribed === 'subscribed') {
						location.reload();
						this.loading = false;
						return;
					}
					else if (this.subscribed === 'wait_1355' || this.subscribed === 'wait_1715') {
						this.smsError = true;
						this.loading = false;
						return;
					}
					else if (this.subscribed === 'subscribe_error') {
						this.error = true;
						this.loading = false;
						return;
					}

					this.getSubscribedValue();
				}, 2000);
			},

			getSubscribedValue() {
				axios.get('/api/subscribe').then(({data}) => {
					this.subscribed = data.status;
				})
			}
		},
	}
</script>

<style scoped>

</style>
