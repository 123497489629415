<template>
	<div>
		<div class="tab">
			<div class="tab_header" :class="{'__no-border __base': base}">
				<a href="" :class="{active: name === active}"
				   @click.prevent="changeTab(name)"
				   v-for="(heading, name) in headings"
				   :key="'heading-' + name" v-text="heading"></a>
				
				<slot name="custom-heading"></slot>
			</div>
			<div class="tab_content">
				<div :class="{active: name === active}" class="tab_block" v-for="(heading, name) in headings" :key="'content' + name">
					<slot :name="name"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['headings', 'activeKey', 'base'],

		data() {
			return {
				active: this.activeKey
			}
		},

		methods: {
			changeTab(name) {
				this.active = name;
			}
		}
	}
</script>
