// Projects name
window.projects = {
	FMK: 'FMK',
	Zet: 'Zet',
	Tcell: 'Tcell',
	Babilon: 'Babilon',
	Default: 'Default',
};


// Current project
window.projectName = window.projects.Default;

function includeScript(filename) {
	var head = document.getElementsByTagName('head')[0];

	var script = document.createElement('script');
	script.src = filename;
	script.type = 'text/javascript';

	head.appendChild(script)
}
